
import './App.css';
import UserUI from './Component/UserUI';

function App() {
  return (
    <div className="App">
      <h1>Welcome to shop now </h1>
      <UserUI />
    </div>
  );
}

export default App;
